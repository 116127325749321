import React, { memo } from "react"
import classes from "./ExperienceSection.module.scss"
import PropTypes from "prop-types"

const ExperienceSection = ({ title, list }) => {
  return (
    <div className={`${classes.expContainer} twoColumnBlock`}>
      <div className="leftColumn">
        <h3 className={classes.expTitle}>{title}</h3>
      </div>
      <div className="rightColumn">
        <div className={classes.description}>
          <ul className={classes.expTimeWrapper}>
            {list.map(item => (
              <li className={classes.expTimeItem} key={item.id}>
                <p className={classes.expTimeCounter}>{item.counter}</p>
                <p className={classes.expTimeSubtitle}>{item.subtitle}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

ExperienceSection.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array,
}

ExperienceSection.defaultProps = {
  isShowMore: false,
}

export default memo(ExperienceSection)
