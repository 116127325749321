import React, { memo, useState } from "react"
import classes                   from "./AccordionList.module.scss"
import AccordionTextItem from "./AccordionTextItem/AccordionTextItem"
import PropTypes from "prop-types"

const AccordionList = ({ title, list, isBoldText }) => {
  const [clicked, setClicked] = useState("0")

  const handleToggle = index => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index)
  }

  return (
    <div className={`${classes.accordionContainer} twoColumnBlock`}>
      <div className="leftColumn">
        <h3 className={classes.accordionTitle}>{title}</h3>
      </div>

      <div className="rightColumn">
        <ul className={classes.accordionWrapper}>
          {list.map((item, index) => (
            <AccordionTextItem
              onToggle={() => handleToggle(index)}
              active={clicked === index}
              key={index}
              list={item}
              isBoldText={isBoldText}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

AccordionList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  isBoldText: PropTypes.bool,
}

AccordionList.defaultProps = {
  isBoldText: true,
}

export default memo(AccordionList)
