import React, { memo } from "react"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import OurWorkCarouselItem from "./OurWorkCarouselItem/OurWorkCarouselItem"
import { useCasesQuery } from "src/hooks/useCasesQuery"
import motorSich from "src/images/motorSich/case.png"
import uxr from "src/images/uxr/screen-preview.png"
import predictly from "src/images/predictly/screen-preview.png"
import tamil from "src/images/tamil/screen-preview.png"
import myLuminette from "src/images/myLuminette/screen-preview.png"
import pinTheLook from "src/images/pinTheLook/case.jpg"
import carSharing from "src/images/carSharing/screen-preview.jpg"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 2,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

const OurWorksCarousel = ({ MultiCarouselClassName, className }) => {
  const { nodes } = useCasesQuery()

  const images = [
    uxr,
    predictly,
    tamil,
    myLuminette,
    motorSich,
    pinTheLook,
    carSharing,
  ]

  return (
    <div className={className}>
      <MultiCarousel
        setup={responsive}
        sectionTitle={"Our Works"}
        className={MultiCarouselClassName}
        buttonGroup={true}
      >
        {nodes.map((item, index) => (
          <OurWorkCarouselItem
            key={index}
            image={images[index]}
            title={item.title}
            description={item.pageContent.mainText[0].firstP}
            link={`/portfolio/${item.name}`}
            tags={item.mainTechnology}
          />
        ))}
      </MultiCarousel>
    </div>
  )
}

export default memo(OurWorksCarousel)
