import React, { memo } from "react"
import { Link } from "gatsby"
import classes from "./ServicesGrid.module.scss"
import SvgIcon from "src/components/SvgIcon"
import ArrowNext from "src/assets/icons/arrow-next.svg"

const ServicesGrid = ({ grid, blocks }) => {
  return (
    <div
      className={`twoColumnBlock ${grid && classes.servicesGridSection} ${
        blocks && classes.servicesBlocksSection
      }`}
    >
      <div className="leftColumn">
        <h3 className={classes.title}>Services</h3>
      </div>
      <div className="rightColumn">
        {grid && (
          <div className={classes.gridContainer}>
            {grid.map(item => (
              <Link
                id={"mainServices" + item.title.replace(/\s/g, "")}
                to={item.link}
                key={item.id}
                className={classes.cardWrapper}
              >
                <h3 className={classes.cardCount}>0{item.id}</h3>
                <div className={classes.cardContent}>
                  <h5 className={classes.cardTitle}>{item.title}</h5>
                  <div className={classes.cardMore}>
                    <p className={classes.cardMoreText}>More</p>
                    <SvgIcon
                      icon={ArrowNext}
                      className={classes.cardMoreIcon}
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {blocks && (
          <div className={classes.blocksContainer}>
            {blocks.map(item => (
              <div className={classes.blockWrapper} key={item.id}>
                <span className={classes.blockCount}>
                  {item.id < 10 ? `0${item.id}` : item.id}
                </span>
                <h4 className={classes.blockTitleService}>{item.title}</h4>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ServicesGrid)
