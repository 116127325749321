import { graphql, useStaticQuery } from "gatsby"

export const useCasesQuery = () => {
  const { allCasesJson } = useStaticQuery(graphql`
    query MyQuery {
      allCasesJson {
        nodes {
          id
          name
          title
          screenPreview
          previewText
          mainTechnology {
            id
            title
          }
          pageContent {
            mainText {
              firstP
            }
          }
        }
      }
    }
  `)
  return allCasesJson
}
